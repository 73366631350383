import axios from 'axios';
import { apiUrl } from '../config.json';

const getProducts = (id_token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.get(`${apiUrl}/products`, { headers });
      
      resolve(response);
    } catch (error) {
      // Come up with better error handling
      console.error(error);
      reject(error);
    };
  });
};

const getProduct = async (id_token, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.get(`${apiUrl}/products/${id}`, { headers });
      
      resolve(response);
    } catch (error) {
      // Come up with better error handling
      console.error(error);
      reject(error);
    };
  });
};

const postProduct = (id_token, product) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.post(`${apiUrl}/products`, product, { headers });
      if (response.status === 200) resolve(response);
      if (response.status === 500) reject(JSON.parse(response.data.response).message);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

const deleteProduct = (id_token, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.delete(`${apiUrl}/products/${id}`, { headers });
  
      if (response.status === 200) resolve('ok');
      if (response.status === 500) reject(JSON.parse(response.data.response).message);
    } catch (error) {
      // Come up with better error handling
      console.error(error);
      reject(error);
    };
  });
};

const putProduct = (id_token, id, product) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.put(`${apiUrl}/products/${id}`, product, { headers });
      if (response.status === 200) resolve(response);
      if (response.status === 500) reject(JSON.parse(response.data.response).message);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

const getTags = (id_token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.get(`${apiUrl}/products/tags`, { headers });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error);
    };
  });
};

const productServices = {
  getProducts,
  getProduct,
  postProduct,
  deleteProduct,
  putProduct,
  getTags
};

export default productServices;
