import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  cellPadding: {
    padding: '16px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  chipFilter: {
    margin: theme.spacing(0.5),
  },
}));

const ProductSummaryCard = (props) => {

  const classes = useStyles();
  const { 
    name,
    price,
    weight,
    description,
    selectedTags,
    handleRemoveTag,
    handleProductSubmit,
  } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Producto" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><b>Nombre:</b></TableCell>
                <TableCell align="right">{name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Precio:</b></TableCell>
                <TableCell align="right">{price}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><b>Peso:</b></TableCell>
                <TableCell align="right">{weight}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.cellPadding}>
          <b>Descripción:</b><br />{description}<br />
        </Box>
        <Box className={classes.cellPadding}>
          <b>Etiquetas:</b><br />
          {
            selectedTags.map(tag => (
              <Chip
                key={tag}
                className={classes.chipFilter}
                label={tag}
                color="primary"
                onDelete={() => handleRemoveTag(tag)}
              />)).sort()
          }<br />
        </Box>
        <Button 
          variant="outlined" 
          size="large" 
          color="primary" 
          style={{ marginTop: '20px', width: '100%' }} 
          onClick={handleProductSubmit}>
          Agregar Producto
        </Button>
      </CardContent>
    </Card>
  )
}

export default ProductSummaryCard;
