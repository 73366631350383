import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  gridcontainer: {
    flexGrow: 1,
  },
}));

const ClientCard = (props) => {

  const classes = useStyles();
  const {
    clientName,
    clientId,
    clientPhone,
    clientEmail,
    handleClientNameChange,
    handleClientIdChange,
    handleClientPhoneChange,
    handleClientEmailChange
  } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Cliente" />
      <CardContent>
        <div className={classes.gridcontainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="nombre"
                label="Nombre"
                variant="outlined"
                value={clientName}
                onChange={handleClientNameChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="cedula"
                label="Cedula"
                variant="outlined"
                value={clientId}
                onChange={handleClientIdChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="telefono"
                label="Telefono"
                variant="outlined"
                value={clientPhone}
                onChange={handleClientPhoneChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                value={clientEmail}
                onChange={handleClientEmailChange}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>



  )
};

export default ClientCard;
