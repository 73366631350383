import React, {useState} from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import jwt from 'jsonwebtoken';
import Home from '../home';
import Login from '../login';

// import NotFound from '../404';

function App() {

  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(null);

  const onLogin = () => {
    setIsLogged(true);
  }

  const onLogout = () => {
    setIsLogged(false);
  }

  const checkToken = () => {
    if(user) {
      const decodedJWT = jwt.decode(user.signInUserSession.idToken.jwtToken);
      if (Date.now() >= decodedJWT.exp * 1000) {
        console.log('EXPIRED TOKEN');
        // onLogout();
      } else {
        console.log('VALID TOKEN');
        // onLogin();
      }
    } else if(window.localStorage.getItem('user')) {
      const localUser = JSON.parse(window.localStorage.getItem('user'));
      const decodedJWT = jwt.decode(localUser.signInUserSession.idToken.jwtToken);
      if (Date.now() >= decodedJWT.exp * 1000) {
        console.log('EXPIRED TOKEN');
        // onLogout();
      } else {
        console.log('VALID TOKEN');
        // setUser(localUser);
        // onLogin();
      }
    } else {
      console.log('USER NOT LOGGED');
      // onLogout();
    }
  }
  
  checkToken();
  
  return (
    <BrowserRouter>
      <Switch>
        {
          isLogged ?  
          <Route path="/" component={() => <Home user={user} />} /> :
          <Route path="/" component={() => <Login onLogin={onLogin} onUserAuth={setUser} />} />
        }
      </Switch>
    </BrowserRouter>
  );
}

export default App;
