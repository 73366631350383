import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { currencyStandardCRFm } from '../../utils/formatter';

const useStyles = makeStyles((theme) => ({
  totalBorderBottom: {
    borderBottom: 'none'
  },
  totalFooter: {
    background: '#eeeeee',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  },
  fixedTotal: {
    position: 'fixed'
  }
}));

const TotalCard = (props) => {

  const classes = useStyles();
  const {
    grossProductTotal,
    discount,
    shippingRequired,
    shippingCost,
    iva,
    totalOrderAmount,
    handleShippingRequirementChange,
    handleDiscountChange,
    handleIVAChange,
    handleOrderSubmit
  } = props;

  return (
    <Card variant="outlined" className={classes.fixedTotal}>
      <CardHeader title="Total" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Subtotal</TableCell>
                <TableCell align="right">{currencyStandardCRFm(grossProductTotal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Descuento</TableCell>
                <TableCell align="right">
                  {
                    currencyStandardCRFm(
                      discount === .90 ? grossProductTotal * .1 :
                        discount === .75 ? grossProductTotal * .25 : 0
                    )
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Envio</TableCell>
                <TableCell align="right">{currencyStandardCRFm(shippingRequired ? Number(shippingCost) : 0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>IVA</TableCell>
                <TableCell align="right">{currencyStandardCRFm(iva ? grossProductTotal * 0.13 : 0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.totalBorderBottom}><strong>TOTAL</strong></TableCell>
                <TableCell className={classes.totalBorderBottom} align="right"><strong>{currencyStandardCRFm(totalOrderAmount)}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Button variant="outlined" size="large" color="primary" onClick={handleOrderSubmit} style={{ marginTop: '20px',width: '100%' }}>
          Guardar Cambios
        </Button>

      </CardContent>
      <CardActions className={classes.totalFooter}>
        <FormControlLabel
          control={
            <Switch
              checked={shippingRequired}
              onChange={handleShippingRequirementChange}
              name="Envio"
              color="primary"
            />
          }
          label="Envio"
          labelPlacement="bottom"
        />

        <FormControlLabel
          control={
            <Switch
              checked={iva}
              onChange={handleIVAChange}
              name="Envio"
              color="primary"
            />
          }
          label="IVA"
          labelPlacement="bottom"
        />

        <FormControl variant="outlined" className={classes.formControl} style={{ width: 130 }}>
          <InputLabel id="tags-filter">Descuento</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={discount}
            onChange={handleDiscountChange}
            label="Descuento"
          >
            <MenuItem value={1}>0%</MenuItem>
            <MenuItem value={.90}>10%</MenuItem>
            <MenuItem value={.75}>25%</MenuItem>
          </Select>
        </FormControl>
      </CardActions>
    </Card>
  )
}

export default TotalCard;
