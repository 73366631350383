import axios from 'axios';
import { apiUrl } from '../config.json';

const getOrders = (id_token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.get(`${apiUrl}/orders`, { headers });

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const getOrder = (id_token, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.get(`${apiUrl}/orders/${id}`, { headers });

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const postOrder = (id_token, order) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.post(`${apiUrl}/orders`, order, { headers });
      
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const putOrder = (id_token, id, order) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.put(`${apiUrl}/orders/${id}`, order, { headers });
      
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const deleteOrder = (id_token, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = { 'Authorization': id_token };
      const response = await axios.delete(`${apiUrl}/orders/${id}`, { headers });

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const orderServices = {
  getOrders,
  getOrder,
  postOrder,
  putOrder,
  deleteOrder
};

export default orderServices;
