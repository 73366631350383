/* eslint-disable array-callback-return */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const DeleteDialog = (props) => {

  const {
    deleteDialog,
    products,
    selectedProducts,
    handleDeleteDialogClose,
    handleProductDelete
  } = props;

  return (
    <Dialog
      open={deleteDialog}
      onClose={handleDeleteDialogClose}
      aria-labelledby="dialogo de eliminacion"
      aria-describedby="confirmacion de eliminacion"
    >
      <DialogTitle id="dialogo de eliminacion">{"Eliminar Producto?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmacion de eliminacion">
          {products.filter(product => {
            if (product.id === selectedProducts[0]) return product
          }).map(product => product.nombre)[0]}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleProductDelete} color="primary" autoFocus>
          Eliminar
        </Button>
        <Button onClick={handleDeleteDialogClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
