import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  datagrid: {
    border: 'none'
  },
  chips: {
    margin: '0px 2px'
  }
}));

const ProductTable = (props) => {
  const classes = useStyles();
  const { 
    products,
    setSelectedProducts 
  } = props;

  const columns = [
    { field: 'id', headerName: 'Id', type: 'string', hide: true },
    { field: 'name', headerName: 'Nombre', type: 'string', flex: 1 },
    {
      field: 'tags', headerName: 'Etiquetas', type: 'string', width: 200 ,renderCell: params => (
        params.value.map(tag => (
          <Chip
            key={tag}
            className={classes.chips}
            label={tag}
            size="small"
            variant="outlined" />
        ))
      )
    },
    { field: 'price', headerName: 'Precio', type: 'number', width: 100 }
  ];

  const rows = products.map(product => {
    return {
      id: product.id,
      name: product.nombre,
      tags: product.etiquetas,
      price: product.precio,
    }
  });

  return (
    <div style={{ height: 640, width: '100%' }}>
      <DataGrid
        className={classes.datagrid}
        rows={rows}
        columns={columns}
        pageSize={10}
        checkboxSelection
        onSelectionChange={(newSelection) => {
          setSelectedProducts(newSelection.rowIds)
        }} />
    </div>
  );
}

export default ProductTable;
