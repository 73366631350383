import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ReorderIcon from '@material-ui/icons/Reorder';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listIcon: {
    minWidth: '30px',
  },
  listNested: {
    paddingLeft: '40px',
  }
}));

const Sidenav = props => {

  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          <ListItem>
            <ListItemText primary="Reportes" />
          </ListItem>
          <ListItem button component={Link} to="/">
            <ListItemIcon className={classes.listIcon}><AssessmentIcon /></ListItemIcon>
            <ListItemText secondary="Dashboard" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Administracion" />
          </ListItem>
          <ListItem button component={Link} to="/productos">
            <ListItemIcon className={classes.listIcon}><ReorderIcon /></ListItemIcon>
            <ListItemText secondary="Productos" />
          </ListItem>
          <ListItem button component={Link} to="/productos/nuevo">
            <ListItemIcon className={classes.listIcon}><PlaylistAddIcon /></ListItemIcon>
            <ListItemText secondary="Agregar Producto" />
          </ListItem>
          <ListItem button component={Link} to="/ventas">
            <ListItemIcon className={classes.listIcon}><ListAltIcon /></ListItemIcon>
            <ListItemText secondary="Ventas" />
          </ListItem>
          <ListItem button component={Link} to="/ventas/orden">
            <ListItemIcon className={classes.listIcon}><PostAddIcon /></ListItemIcon>
            <ListItemText secondary="Orden" />
          </ListItem>
        </List>
      </div>
      <Toolbar />
    </Drawer>
  )
}

export default Sidenav;
