import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Grid';

import LoginCard from './login';
import ForceNewPasswordCard from './forceNewPassword';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    height: '100vh',
    background: '#3f51b5',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 100px'
  },
  flexLogo: {
    textAlign: 'center',
    flex: '3 1 auto',
  },
  flexLogin: {
    flex: '1 1 auto',
  },
  title: {
    fontWeight: 'lighter',
    color: '#ffffff',
  },
}));

//EMAIL: msolano00@gmail.com
//EMAIL: marv.solano@outlook.com
//PWD: Waffles_4_Life
//PWD2: OMG_wtf_999

const Login = (props) => {
  const { onLogin, onUserAuth } = props;

  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorDetails, setErrorDetails] = useState('');
  const poolData = {
    UserPoolId: 'us-east-1_YgXm3uByp',
    ClientId: '5i2chj74026ge1abg0fa557p1'
  };
  const UserPool = new CognitoUserPool(poolData);

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const onNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  }

  const onSubmit = (event) => {
    event.preventDefault();

    const _user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    setUser(_user);
    _user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        window.localStorage.setItem('user', JSON.stringify(_user));
        onUserAuth(_user);
        setUser(_user);
        setError(false);
        onLogin();
      },
      onFailure: (error) => {
        console.error('Error: ', error);
        setError(true);
        setErrorDetails(error);
      },
      newPasswordRequired: (data) => {
        setUser(_user);
        setChangePassword(true);
      }
    });
  };

  const onNewPasswordSubmit = (event) => {
    event.preventDefault();

    user.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: (result) => {
        setError(false);
        setChangePassword(false);
      },
      onFailure: (error) => {
        setError(true);
        setErrorDetails(error);
      }
    });
  }

  return (
    <Box className={classes.flexContainer}>
      <Box className={classes.flexLogo}>
        <Typography variant="h2" className={classes.title}>
          Velasol
        </Typography>
      </Box>
      <Box className={classes.flexLogin}>
        {
          changePassword ?
            <ForceNewPasswordCard 
              onNewPasswordChange={onNewPasswordChange} 
              onNewPasswordSubmit={onNewPasswordSubmit}
              displayError={error}
              errorMessage={errorDetails.message} />
            :
            <LoginCard 
              onEmailChange={onEmailChange}
              onPasswordChange={onPasswordChange}
              onSubmit={onSubmit}
              displayError={error}
              errorMessage={errorDetails.message} />
        }
      </Box>
    </Box>
  )
}

export default Login;
