import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkMaterial from '@material-ui/core/Link';
import ClientCard from './clientCard';
import ProductCard from './productCard';
import ShippingCard from './shippingCard';
import TotalCard from './totalCard';
import { Link } from 'react-router-dom';
import orderServices from '../../services/orders';
import { provincias, cantones } from './shippingData.json';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  gridcontainer: {
    flexGrow: 1,
  },
  tableHeader: {
    background: '#ffffff',
  },
}));

const AddOrder = (props) => {

  const classes = useStyles();
  const { 
    id_token,
    products,
    handleOrderRefresh,
    setSuccess,
    setSuccessMessage,
    setError,
    setErrorMessage
  } = props;
  const initializeClient = { nombre: '', cedula: '', email: '', telefono: '' };
  const initializeAddress = { provincia: '', canton: '', distrito: '', exacta: '' }
  const [client, setClient] = useState(initializeClient)
  const [addedProducts, setAddedProducts] = useState([]);
  const [shippingRequired, setShippingRequired] = useState(false);
  const [address, setAddress] = useState(initializeAddress);
  const [shippingCost, setShippingCost] = useState(0);
  const [iva, setIVA] = useState(false);
  const [discount, setDiscount] = useState(1);
  const [totalOrderAmount, setTotalOrderAmount] = useState(0);
  const [filteredCantons, setFilteredCantons] = useState([]);
  const [grossProductTotal, setGrossProductTotal] = useState(0);

  // HTTP Requests

  const postOrder = async (order) => {
    try {
      const response = await orderServices.postOrder(id_token, order);
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage('La orden de compra fue creada!');
        handleOrderRefresh();
        
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    }
  }

  // Support Functions

  const duplicateValidation = (product) => {
    return addedProducts.some(prod => prod.id === product.id);
  }

  const mergeAllCantons = (cantones) => {
    const cantons = cantones.map(province => province.cantones);
    return cantons.flat();
  }

  const filterCantons = (province) => {
    const cantons = cantones.filter(provincia => provincia.provincia === province);
    return cantons[0].cantones;
  }

  // Event Handlers

  const handleClientNameChange = (event) => {
    setClient({ ...client, nombre: event.target.value });
  }

  const handleClientIdChange = (event) => {
    setClient({ ...client, cedula: event.target.value });
  }

  const handleClientEmailChange = (event) => {
    setClient({ ...client, email: event.target.value });
  }

  const handleClientPhoneChange = (event) => {
    setClient({ ...client, telefono: event.target.value });
  }

  const handleProductSelect = (product) => {
    const prod = {
      id: product.id,
      producto: product.nombre,
      precio: product.precio,
      cantidad: 1
    }
    if (!duplicateValidation(prod)) setAddedProducts(Array.from(new Set([...addedProducts, prod])));
  }

  const handleProductDelete = (id) => {
    const prods = addedProducts.filter(product => product.id !== id).map(product => {
      return {
        id: product.id,
        producto: product.producto,
        precio: product.precio,
        cantidad: product.cantidad
      }
    });
    setAddedProducts(prods);
  }

  const handleRemoveQuantity = (id) => {
    const prods = addedProducts.map(product => {
      return {
        id: product.id,
        producto: product.producto,
        precio: product.precio,
        cantidad: product.id === id ? product.cantidad > 1 ? --product.cantidad : 1 : product.cantidad
      }
    })
    setAddedProducts(prods);
  }

  const handleAddQuantity = (id) => {
    const prods = addedProducts.map(product => {
      return {
        id: product.id,
        producto: product.producto,
        precio: product.precio,
        cantidad: product.id === id ? ++product.cantidad : product.cantidad
      }
    });
    setAddedProducts(prods);
  }

  const handleProvinceSelect = (province) => {
    if (province) {
      setFilteredCantons(filterCantons(province));
      setAddress({ ...address, provincia: province });
    } else {
      setFilteredCantons(mergeAllCantons(cantones))
    }
  }

  const handleCantonSelect = (canton) => {
    if (canton) setAddress({ ...address, canton: canton.nombre });
  }

  const handleExactAddress = (direction) => {
    setAddress({ ...address, exacta: direction });
  }

  const handleShippingCostChange = (event) => {
    setShippingCost(event.target.value)
  }

  const handleShippingRequirementChange = (event) => {
    setShippingRequired(event.target.checked);
  }

  const handleIVAChange = (event) => {
    setIVA(event.target.checked);
  }

  const handleDiscountChange = (event) => {
    setDiscount(event.target.value)
  }

  const handleResetForm = () => {
    setClient(initializeClient);
    setAddedProducts([]);
    setShippingRequired(false);
    setAddress(initializeAddress);
    setShippingCost(0);
    setIVA(false);
    setDiscount(1);
  }

  const handleOrderSubmit = () => {
    const date = new Date();
    const order = {
      "fecha": `${date.getFullYear()}/${date.getDate()}/${date.getMonth()+1}`,
      "cliente": client,
      "pedido": addedProducts,
      "envio": {
        "requerido": shippingRequired,
        "direccion": address,
        "precio": shippingCost
      },
      "iva": iva,
      "descuento": discount,
      "total": totalOrderAmount,
      "estado": "pendiente"
    };

    if (order.cliente.nombre === '') {
      setError(true);
      setErrorMessage('El nombre del cliente no puede estar vacio');
    } else if (order.cliente.telefono === '') {
      setError(true);
      setErrorMessage('El telefono del cliente no puede estar vacio');
    } else if (order.pedido.length === 0) {
      setError(true);
      setErrorMessage('No hay productos agregados a la orden');
    } else if (order.envio.requerido && order.envio.direccion.provincia === '') {
      setError(true);
      setErrorMessage('La provincia del envio no puede estar vacia');
    } else if (order.envio.requerido && order.envio.direccion.canton === '') {
      setError(true);
      setErrorMessage('El cantón del envio no puede estar vacio');
    } else if ((order.envio.requerido && order.precio === 0) || (order.envio.requerido && order.precio === '')) {
      setError(true);
      setErrorMessage('El precio del envio no puede estar vacio');
    } else {
      postOrder(order);
      handleResetForm();
    }
  }

  // React to state changes

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (filteredCantons.length === 0) setFilteredCantons(mergeAllCantons(cantones))
  });

  useEffect(() => {
    setGrossProductTotal(addedProducts.reduce((total, product) => total + (product.precio * product.cantidad), 0));
  }, [addedProducts])

  useEffect(() => {
    const salesTax = iva ? 1.13 : 1;
    const shipping = shippingRequired ? shippingCost : 0;
    const netTotal = ((grossProductTotal * discount) + Number(shipping)) * salesTax;
    setTotalOrderAmount(netTotal)
  }, [grossProductTotal, iva, shippingCost, discount, shippingRequired])

  return (
    <React.Fragment>
      <Container>

        <Breadcrumbs aria-label="breadcrumb">
          <LinkMaterial color="inherit" component={Link} to="/">
            Home
          </LinkMaterial>
          <LinkMaterial color="inherit" component={Link} to="/ventas">
            Ventas
          </LinkMaterial>
          <Typography color="textPrimary">Orden</Typography>
        </Breadcrumbs>

        <Typography variant="h4" gutterBottom>
          Orden
        </Typography>
        <div className={classes.gridcontainer} >
          <Grid container spacing={3} alignItems="flex-start">
            <Grid container item xs={8} spacing={3}>

              <Grid item xs={12} sm={12} lg={12}>
                <ClientCard
                  clientName={client.nombre}
                  clientId={client.cedula}
                  clientPhone={client.telefono}
                  clientEmail={client.email}
                  handleClientNameChange={handleClientNameChange}
                  handleClientIdChange={handleClientIdChange}
                  handleClientPhoneChange={handleClientPhoneChange}
                  handleClientEmailChange={handleClientEmailChange}
                />
              </Grid>
              
              <Grid item xs={12} sm={12} lg={12}>
                <ProductCard 
                  products={products}
                  addedProducts={addedProducts}
                  handleProductSelect={handleProductSelect}
                  handleRemoveQuantity={handleRemoveQuantity}
                  handleAddQuantity={handleAddQuantity}
                  handleProductDelete={handleProductDelete}
                />
              </Grid>

              <Grid item xs={12} sm={12} lg={12}>
                {
                  shippingRequired ?
                    <ShippingCard 
                      provincias={provincias}
                      filteredCantons={filteredCantons}
                      handleProvinceSelect={handleProvinceSelect}
                      handleCantonSelect={handleCantonSelect}
                      handleExactAddress={handleExactAddress}
                      handleShippingCostChange={handleShippingCostChange}
                    /> :
                    ''
                }
              </Grid>
            </Grid>
            <Grid container item xs={4} spacing={3}>

              <Grid item xs={12} sm={12} lg={12}>
                <TotalCard 
                  grossProductTotal={grossProductTotal}
                  discount={discount}
                  shippingRequired={shippingRequired}
                  shippingCost={shippingCost}
                  iva={iva}
                  totalOrderAmount={totalOrderAmount}
                  handleShippingRequirementChange={handleShippingRequirementChange}
                  handleDiscountChange={handleDiscountChange}
                  handleIVAChange={handleIVAChange}
                  handleOrderSubmit={handleOrderSubmit}
                />
              </Grid>
            </Grid>

          </Grid>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default AddOrder;
