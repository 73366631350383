import React, { useState, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from '../header';
import Sidenav from '../sidenav';
import Dashboard from '../dashboard';
import Orders from '../orders';
import Products from '../products';
import AddOrder from '../addorder';
import AddProduct from '../addproduct';
import UpdateProduct from '../updateproduct';
import UpdateOrder from '../updateorder';
import Alerts from './alerts';
import productServices from '../../services/products';
import orderServices from '../../services/orders';
import { appName } from '../../config.json';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
}));


const Home = (props) => {
  const classes = useStyles();
  const id_token = props.user.signInUserSession.idToken.jwtToken;
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tags, setTags] = useState([]);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getProducts = async (mounted = true) => {
    try {
      const response = await productServices.getProducts(id_token);
      if (mounted && response.status === 200) {
        setProducts(response.data.response.Items.sort((a, b) => a.nombre.localeCompare(b.nombre)));
        setTags(Array.from(new Set(response.data.response.Items.map(product => product.etiquetas).flat())).sort());
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    }
  }

  const getOrders = async (mounted = true) => {
    try {
      const response = await orderServices.getOrders(id_token);
      if (mounted && response.status === 200) setOrders(response.data.response.Items);
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    }
  }

  const handleProductRefresh = () => getProducts();

  const handleOrderRefresh = () => getOrders();

  const handleSuccessAlertClose = () => {
    setSuccess(false);
    setSuccessMessage('');
  };

  const handleErrorAlertClose = () => {
    setError(false);
    setErrorMessage('');
  };

  useEffect(() => {
    let mounted = true;
    getProducts(mounted);
    getOrders(mounted);
    return () => mounted = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header appName={appName} />
      <Sidenav />

      <main className={classes.content}>
        <br /><br /><br />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/productos" component={() => <Products id_token={id_token} products={products} tags={tags} handleProductRefresh={handleProductRefresh} setSuccess={setSuccess} setSuccessMessage={setSuccessMessage} setError={setError} setErrorMessage={setErrorMessage} />} />
          <Route exact path="/productos/nuevo" component={() => <AddProduct id_token={id_token} tags={tags} handleProductRefresh={handleProductRefresh} setSuccess={setSuccess} setSuccessMessage={setSuccessMessage} setError={setError} setErrorMessage={setErrorMessage} />} />
          <Route exact path="/productos/:id" component={() => <UpdateProduct id_token={id_token} tags={tags} handleProductRefresh={handleProductRefresh} setSuccess={setSuccess} setSuccessMessage={setSuccessMessage} setError={setError} setErrorMessage={setErrorMessage}  />} />
          <Route exact path="/ventas" component={() => <Orders id_token={id_token} orders={orders} handleOrderRefresh={handleOrderRefresh} setSuccess={setSuccess} setSuccessMessage={setSuccessMessage} setError={setError} setErrorMessage={setErrorMessage} />} />
          <Route exact path="/ventas/orden" component={() => <AddOrder id_token={id_token} handleOrderRefresh={handleOrderRefresh} products={products} setSuccess={setSuccess} setSuccessMessage={setSuccessMessage} setError={setError} setErrorMessage={setErrorMessage} />} />
          <Route exact path="/ventas/:id" component={() => <UpdateOrder id_token={id_token} handleOrderRefresh={handleOrderRefresh} products={products} setSuccess={setSuccess} setSuccessMessage={setSuccessMessage} setError={setError} setErrorMessage={setErrorMessage} />} />
        </Switch>
        <Alerts 
          success={success}
          successMessage={successMessage}
          error={error}
          errorMessage={errorMessage}
          handleSuccessAlertClose={handleSuccessAlertClose}
          handleErrorAlertClose={handleErrorAlertClose}
        />
      </main>
    </div>
  );
}

export default Home;
