import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  gridcontainer: {
    flexGrow: 1,
  },
}));


const ShippingCard = (props) => {

  const classes = useStyles();
  const {
    provincia,
    canton,
    precio,
    provincias,
    filteredCantons,
    handleProvinceSelect,
    handleCantonSelect,
    handleExactAddress,
    handleShippingCostChange
  } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Envio" />
      <CardContent>
        <div className={classes.gridcontainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} lg={5}>
              <Autocomplete
                id="province-combo-box"
                value={provincia}
                options={provincias}
                getOptionLabel={(provincia) => {
                  if(provincia) return provincia;
                  else if(provincia === '') return '';
                }}
                onChange={(event, newValue) => handleProvinceSelect(newValue)}
                renderInput={(params) => <TextField {...params} label="Provincia" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={5} lg={5}>
              <Autocomplete
                id="canton-combo-box"
                value={canton}
                options={filteredCantons}
                getOptionLabel={(canton) => {
                 if(canton) return canton;
                 else if(canton === '') return '';
                }}
                onChange={(event, newValue) => handleCantonSelect(newValue)}
                renderInput={(params) => <TextField {...params} label="Cantón" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={2} lg={2}>
              <TextField
                id="precio-envio"
                label="Precio"
                type="number"
                value={precio}
                style={{ width: '100%' }}
                onChange={handleShippingCostChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <TextField
                id="direccion-exacta"
                label="Dirección Exacta"
                multiline
                rows={4}
                style={{ width: '100%' }}
                onChange={handleExactAddress}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>

      </CardContent>
    </Card>
  )
};

export default ShippingCard;
