import React from 'react';
import Chip from '@material-ui/core/Chip';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  datagrid: {
    border: 'none'
  }
}));

const OrderTable = (props) => {
  const classes = useStyles();
  const { data, setSelectedOrders } = props;

  const columns = [
    { field: 'id', headerName: 'Orden', type: 'string', width: 500 },
    { field: 'date', headerName: 'Fecha', type: 'date', width: 120},
    { field: 'client', headerName: 'Cliente', type: 'string', width: 200 },
    { field: 'status', headerName: 'Estado', type: 'string', width: 100, renderCell: params => (
      <Chip 
        color={params.value === 'completa' ? 'primary' : params.value === 'cancelada' ? 'secondary' : 'default'}
        label={params.value} 
        size="small" 
        variant="outlined"/>
    )},
    { field: 'total', headerName: 'Total', type: 'number', width: 100 }
  ]

  const rows = data.map(order => {
    return { 
      id:order.id, 
      date: order.fecha, 
      client: order.cliente.nombre, 
      status: order.estado, 
      total: order.total 
    }
  });
  
  return (
    <div style={{ height: 640, width: '100%' }}>
      <DataGrid
        className={classes.datagrid}
        rows={rows} 
        columns={columns} 
        pageSize={10} 
        checkboxSelection
        sortModel={[
          {
            field: 'date',
            sort: 'desc',
          },
        ]}
        onSelectionChange={(newSelection) => {
          setSelectedOrders(newSelection.rowIds)
        }}/>
    </div>
  )
};

export default OrderTable;
