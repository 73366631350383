import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  gridcontainer: {
    flexGrow: 1,
  },
}));

const filter = createFilterOptions();

const ProductDetailsCard = (props) => {

  const classes = useStyles();
  const { 
    name,
    weight,
    price,
    // currentTag,
    tags,
    description,
    handleAddTag,
    handleNameChange,
    handleWeightChange,
    handlePriceChange,
    handleDescriptionChange
  } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Detalles" />
      <CardContent>
        <div className={classes.gridcontainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="nombre"
                label="Nombre"
                variant="outlined"
                value={name}
                onChange={handleNameChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="peso"
                label="Peso"
                variant="outlined"
                value={weight}
                onChange={handleWeightChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                id="precio"
                label="Precio"
                variant="outlined"
                inputProps={{ pattern: '[0-9]*' }}
                value={price}
                onChange={handlePriceChange}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Autocomplete
                // value={currentTag}
                getOptionSelected={(option, valueSelected) => option.value === valueSelected.value}
                onChange={(event, newValue) => {
                  if (newValue) handleAddTag(newValue.inputValue)
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: `Agregar "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={tags.map(tag => {
                  return {
                    inputValue: tag,
                    title: tag
                  }
                })}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') return option;
                  if (option.inputValue) return option.inputValue;
                  return option.title;
                }}
                renderOption={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params} label="Etiquetas" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <TextField
                id="descripcion"
                label="Descripción"
                variant="outlined"
                multiline
                rows={4}
                value={description}
                onChange={handleDescriptionChange}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  )
}

export default ProductDetailsCard;
