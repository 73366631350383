import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  login: {
    textAlign: 'center'
  },
  alertContainer: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  centerCardButton: {
    justifyContent: 'center'
  },
}));

const LoginCard = (props) => {
  const classes = useStyles();
  const {
    onEmailChange,
    onPasswordChange,
    onSubmit,
    displayError,
    errorMessage,
  } = props;

  return (
    <Card className={classes.login}>
      <br /><br />
      <CardHeader title="Login" />
      <br />
      <CardContent>
        <form>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            onChange={onEmailChange}
            fullWidth={true}
          />
          <br /><br /><br />
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            type="password"
            onChange={onPasswordChange}
            fullWidth={true}
          />
        </form>
      </CardContent>
      <br />
      {
        displayError ?
          <div className={classes.root}>
            <Alert severity="error">{errorMessage}</Alert>
          </div> :
          ''
      }
      <CardActions classes={{ root: classes.centerCardButton }}>
        <Button color="primary" onClick={onSubmit}>Login</Button>
      </CardActions>
      <br /><br />
    </Card>
  )
};

export default LoginCard;
