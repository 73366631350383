/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import OrderFilters from './filters';
import OrderTable from './table';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkMaterial from '@material-ui/core/Link';
import SubMenu from './subMenu';
import { Link } from 'react-router-dom';
import orderServices from '../../services/orders';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

const Orders = (props) => {
  const classes = useStyles();
  const { 
    id_token, 
    orders,
    handleOrderRefresh,
    setSuccess,
    setSuccessMessage,
    setError,
    setErrorMessage
  } = props;
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [rangeFlag, setRangeFlag] = useState(false);
  const [selectedInitialDate, setSelectedInitialDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [orderStatusFilter, setOrderStatusFilter] = useState('todos');
  const [searchBar, setSearchBar] = useState('');
  const [selectedOrders, setSelectedOrders] = useState([]);

  // FILTERS 

  const handleInitialDateChange = (date) => setSelectedInitialDate(date);

  const handleEndDateChange = (date) => setSelectedEndDate(date);

  const handleStatusFilterChange = (event) => setOrderStatusFilter(event.target.value);

  const onSearchBarFilter = (event) => setSearchBar(event.target.value);

  const onFilterData = (searchBar, status) => {
    const filteredSet = orders.filter(order => {
      if (searchBar !== '' && status !== 'todos') {
        if (order.cliente.nombre.includes(searchBar) && order.estado === status) return order;
      } else if (searchBar === '' && status !== 'todos') {
        if (order.estado === status) return order;
      } else if (searchBar !== '' && status === 'todos') {
        if (order.cliente.nombre.includes(searchBar)) return order;
      } else return order;
    });
    setFilteredOrders(filteredSet);
  }

  const handleRangeFlagChange = (event) => setRangeFlag(event.target.checked);

  const handleOrderStatusChange = (id, status) => {
    const order = orders.filter(order => order.id === selectedOrders[0])[0];
    order.estado = status;
    orderServices.putOrder(id_token, id, order)
    .then(response => {
      setSuccess(true);
      setSuccessMessage('La orden fue actualizada!');
      handleOrderRefresh();
    })
    .catch(error => {
      setError(true);
      setErrorMessage(error);
    });
  }; 

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { onFilterData(searchBar, orderStatusFilter) }, [searchBar, orderStatusFilter])

  return (
    <React.Fragment>
      <Container>

        <Breadcrumbs aria-label="breadcrumb">
          <LinkMaterial color="inherit" component={Link} to="/">
            Home
          </LinkMaterial>
          <Typography color="textPrimary">Ventas</Typography>
        </Breadcrumbs>

        <Typography variant="h4" gutterBottom>
          Ventas
        </Typography>

        <Paper className={classes.root}>

          <OrderFilters 
            onSearchBarFilter={onSearchBarFilter}
            orderStatus={orderStatusFilter}
            onStatusFilterChange={handleStatusFilterChange}
            selectedInitialDate={selectedInitialDate}
            onInitialDateChange={handleInitialDateChange}
            selectedEndDate={selectedEndDate}
            onEndDateChange={handleEndDateChange}
            rangeFlag={rangeFlag}
            onRangeFlagChange={handleRangeFlagChange}
          />

          <OrderTable 
            data={filteredOrders}
            setSelectedOrders={setSelectedOrders} 
          />

          <SubMenu 
            selectedOrders={
              orders.filter(order => {
                if(selectedOrders.includes(order.id)) return order;
              }).map(order => {
                return { id: order.id, estado: order.estado} 
              })
            }
            handleOrderStatusChange={handleOrderStatusChange}
          />

        </Paper>
      </Container>
    </React.Fragment>
  )
}

export default Orders;
