import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  box: {
    '& > *': {
      margin: theme.spacing(2),
    },
    verticalAlign: 'middle'
  },
  inputWidth: {
    '& > *': {
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    maxWidth: 300,
    '& > *': {
      width: '25ch',
    },
  },
  chipContainer: {
    display: 'inline-flex',
    margin: '22px 0px'
  },
  chipFilter: {
    margin: theme.spacing(0.5),
  },
  addButton: {
    margin: '20px 16px',
    float: 'right'
  }
}));

const FilterControl = (props) => {
  const classes = useStyles();
  const { 
    tags,
    searchValue,
    selectedTag,
    selectedTagArray,
    onSearchBarFilter,
    onTagsFilterSelect,
    onTagsFilterDeselect,
    handleProductRefresh
  } = props;

  return (
    <Box className={classes.box}>
      <TextField
        id="search-filter"
        value={searchValue}
        className={classes.inputWidth}
        label="Busqueda"
        variant="outlined"
        onChange={onSearchBarFilter}
      />

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="tags-filter">Etiquetas</InputLabel>
        <Select
          labelId="tags-filter"
          id="tags-filter"
          value={selectedTag}
          onChange={onTagsFilterSelect}
          label="Etiquetas"
        >
          <MenuItem value="">Ninguna</MenuItem>
          {tags.map((tag) => (
            <MenuItem key={tag} value={tag}>
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <span className={classes.chipContainer}>
        {selectedTagArray.map(tag => (
          <Chip
            key={tag}
            className={classes.chipFilter}
            label={tag}
            color="primary"
            onDelete={() => onTagsFilterDeselect(tag)}
          />
        ))}
      </span>

      <IconButton aria-label="add" color="primary" className={classes.addButton} component={Link} to="/productos/nuevo">
        <AddIcon />
      </IconButton>
      <IconButton aria-label="add" color="primary" className={classes.addButton} onClick={handleProductRefresh}>
        <RefreshIcon />
      </IconButton>
    </Box>
  )
}

export default FilterControl;
