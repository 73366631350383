/**
 * currencyShortFm
 * Formats a number in shorter, human readable notation
 * @param {number} value The number to be formatted
 * @param {string} prefix The string to be prefixed to the formatted value, defaults to nothing
 * @param {number} decimals The amount of decimals to be displayed, defaults to 1
 * @returns {string} The formatted number in the following notation (-)XX[.XX][UNIT]
 */
function currencyShortFm(value, prefix = '', decimals = 1) {
  if (value === null || value === 0) return '0';
  // eslint-disable-next-line no-param-reassign
  decimals = decimals < 0 ? 0 : decimals;
  const power = value.toPrecision(2).split('e');
  const index = power.length === 1 ? 0 : Math.floor(Math.min(power[1].slice(1), 14) / 3);
  // eslint-disable-next-line no-restricted-properties
  const fixedValue = index < 1 ? value : value / Math.pow(10, index * 3);
  const absoluteFixedValue = fixedValue < 0 ? fixedValue : Math.abs(fixedValue);
  const finalValue =
    absoluteFixedValue < 0
      ? `-$${Math.abs(absoluteFixedValue).toFixed(decimals)}`
      : `${prefix}${absoluteFixedValue.toFixed(decimals)}`;
  return `${finalValue}${['', 'K', 'M', 'B', 'T'][index]}`;
}

/**
 * currencyStandardCRFm
 * Formats a number into CR currency standard format
 * @param {number} value The number to be formatted
 * @returns {string} The formatted number as a USA standard (-)₡XXX,XXX.XX
 */
function currencyStandardCRFm(value) {
  return `₡${new Intl.NumberFormat().format((value).toFixed(2))}`;
}

/**
 * currencyStandardUSFm
 * Formats a number into USA currency standard format
 * @param {number} value The number to be formatted
 * @returns {string} The formatted number as a USA standard (-)$XXX,XXX.XX
 */
function currencyStandardUSFm(value) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}
 
/**
 * percentageFm
 * Formats a value into a percentage by adding a % suffix and fixing number of decimals
 * @param {number} value The number to be formatted
 * @returns {string} The formatted number as a percentage (-)XX.X%
 */
function percentageFm(value, decimals = 1) {
  return `${value.toFixed(decimals)}%`;
}
 
module.exports = {
  currencyShortFm,
  currencyStandardUSFm,
  currencyStandardCRFm,
  percentageFm
};