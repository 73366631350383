import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkMaterial from '@material-ui/core/Link';
import ProductDetailsCard from './productDetailsCard';
import ProductSummaryCard from './productSummaryCard';
import { Link, useParams } from "react-router-dom";
import productServices from '../../services/products';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  gridcontainer: {
    flexGrow: 1,
  },
  cellPadding: {
    padding: '16px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  chipFilter: {
    margin: theme.spacing(0.5),
  },
}));

const UpdateProduct = (props) => {

  const classes = useStyles();
  const { id } = useParams();
  const { 
    id_token,
    tags,
    handleProductRefresh,
    setSuccess,
    setSuccessMessage,
    setError,
    setErrorMessage
  } = props;
  const initializeProduct = { nombre: '', peso: '', precio: '', etiquetas: [], descripcion: '' };
  const [product, setProduct] = useState(initializeProduct);

  // HTTP REQUESTS

  const getProduct = async () => {
    productServices.getProduct(id_token, id)
    .then(response => {
      if (response.status === 200) setProduct(response.data.Item);
      if (response.status === 500) {
        setError(true);
        setErrorMessage(JSON.parse(response.data.response).message);
      }
    })
    .catch(error => {
      setError(true);
      setErrorMessage(error);
    });
  }

  const putProduct = async () => {
    try {
      const response = await productServices.putProduct(id_token, id, product);
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage('El producto fue modificado');
        handleFormReset();
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    }
  }

  // EVENT HANDLERS

  const handleNameChange = (event) => setProduct({...product, nombre: event.target.value});

  const handleWeightChange = (event) => setProduct({...product, peso: event.target.value});

  const handlePriceChange = (event) => setProduct({...product, precio: event.target.value});

  const handleDescriptionChange = (event) => setProduct({...product, descripcion: event.target.value});

  const handleAddTag = (tag) => setProduct({...product, etiquetas: [...new Set([...product.etiquetas, tag])]});

  const handleRemoveTag = (tag) => setProduct({...product, etiquetas: product.etiquetas.filter((el) => el !== tag)});
  
  const handleFormReset = () => {
    setProduct(initializeProduct);
  }

  const handleProductSubmit = () => {
    putProduct();
    handleProductRefresh();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (product.nombre === '') getProduct() }, [product])

  return (
    <React.Fragment>
      <Container>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkMaterial color="inherit" component={Link} to="/">
            Home
          </LinkMaterial>
          <LinkMaterial color="inherit" component={Link} to="/productos">
            Productos
          </LinkMaterial>
          <Typography color="textPrimary">Modificar Producto</Typography>
        </Breadcrumbs>

        <Typography variant="h4" gutterBottom>
          {product.nombre ? product.nombre : 'Producto'}
        </Typography>

        <div className={classes.gridcontainer} >
          <Grid container spacing={3} alignItems="flex-start">
            <Grid container item xs={8} spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <ProductDetailsCard 
                  name={product.nombre}
                  weight={product.peso}
                  price={product.precio}
                  tags={tags}
                  description={product.descripcion}
                  handleAddTag={handleAddTag}
                  handleNameChange={handleNameChange}
                  handleWeightChange={handleWeightChange}
                  handlePriceChange={handlePriceChange}
                  handleDescriptionChange={handleDescriptionChange}
                />
              </Grid>
            </Grid>

            <Grid container item xs={4} spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <ProductSummaryCard 
                  name={product.nombre}
                  price={product.precio}
                  weight={product.peso}
                  description={product.descripcion}
                  selectedTags={product.etiquetas}
                  handleRemoveTag={handleRemoveTag}
                  handleProductSubmit={handleProductSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>

      </Container>
    </React.Fragment>
  );
}

export default UpdateProduct;
