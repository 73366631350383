import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { currencyStandardCRFm } from '../../utils/formatter';

const useStyles = makeStyles((theme) => ({
  gridcontainer: {
    flexGrow: 1,
  },
  tableHeader: {
    background: '#ffffff',
  },
}));

const ProductCard = (props) => {

  const classes = useStyles();
  const {
    products,
    addedProducts,
    handleProductSelect,
    handleRemoveQuantity,
    handleAddQuantity,
    handleProductDelete
  } = props;

  return (
    <Card variant="outlined">
      <CardHeader title="Pedido" />
      <CardContent>
        <Autocomplete
          id="product-combo-box"
          options={products}
          getOptionLabel={(product) => product.nombre}
          onChange={(event, newValue) => {
            if (newValue) handleProductSelect(newValue);
          }}
          renderInput={(params) => <TextField {...params} label="Productos" variant="outlined" />}
        />
        <br />

        <TableContainer>
          <Table stickyHeader size="small" aria-label="order table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}><strong>Producto</strong></TableCell>
                <TableCell className={classes.tableHeader} align="right"><strong>Cantidad</strong></TableCell>
                <TableCell className={classes.tableHeader} align="right"><strong>Precio</strong></TableCell>
                <TableCell className={classes.tableHeader} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                addedProducts.map(product => (
                  <TableRow key={product.id}>
                    <TableCell component="th" scope="row">{product.producto}</TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="remove" onClick={() => handleRemoveQuantity(product.id)}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      {product.cantidad}
                      <IconButton aria-label="add" onClick={() => handleAddQuantity(product.id)}>
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">{currencyStandardCRFm(product.precio)}</TableCell>
                    <TableCell align="right">
                      <IconButton aria-label="delete" onClick={() => handleProductDelete(product.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>



  )
}

export default ProductCard;
