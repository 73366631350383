import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkMaterial from '@material-ui/core/Link';
import ProductDetailsCard from './productDetails';
import ProductSummaryCard from './productSummary';
import { Link } from 'react-router-dom';
import productService from '../../services/products';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  gridcontainer: {
    flexGrow: 1,
  },
  cellPadding: {
    padding: '16px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  chipFilter: {
    margin: theme.spacing(0.5),
  },
}));

const AddProduct = (props) => {

  const classes = useStyles();
  const { 
    id_token,
    tags,
    handleProductRefresh,
    setSuccess,
    setSuccessMessage,
    setError,
    setErrorMessage
  } = props;
  const [name, setName] = useState('');
  const [weight, setWeight] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [currentTag, setCurrentTag] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  // HTTP REQUESTS

  const postProduct = async (product) => {
    try {
      const response = await productService.postProduct(id_token, product);
      if (response.status === 200) {
        setSuccess(true);
        setSuccessMessage('El producto nuevo fue agregado');
        handleProductRefresh();
      }
      if (response.status === 500) {
        setError(true);
        setErrorMessage(JSON.parse(response.data.response).message);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error);
    }
  }

  // EVENT HANDLERS

  const handleNameChange = (event) => setName(event.target.value);

  const handleWeightChange = (event) => setWeight(event.target.value);

  const handlePriceChange = (event) => setPrice(event.target.value);

  const handleDescriptionChange = (event) => setDescription(event.target.value);

  const handleAddTag = (tag) => setSelectedTags([...new Set([...selectedTags, tag])]);

  const handleRemoveTag = (tag) => setSelectedTags(selectedTags.filter((el) => el !== tag));

  const handleFormReset = () => {
    setName('');
    setWeight('');
    setPrice('');
    setDescription('');
    setCurrentTag('');
    setSelectedTags([]);
  }

  const handleProductSubmit = () => {
    const product = {
      nombre: name,
      peso: weight,
      precio: price,
      etiquetas: selectedTags,
      descripcion: description
    };

    if (!product.nombre || product.nombre === '') {
      setError(true);
      setErrorMessage('El nombre del producto no puede estar vacio');
    } else if (Number(product.precio) <= 0) {
      setError(true);
      setErrorMessage('El precio del producto no puede estar vacio ni ser cero');
    } else if (product.etiquetas.length === 0) {
      setError(true);
      setErrorMessage('El producto requiere seleccionar por lo menos una etiqueta');
    } else if (product.nombre !== '' && product.precio > 0) {
      postProduct(product);
      handleFormReset();
    }
  }

  return (
    <React.Fragment>
      <Container>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkMaterial color="inherit" component={Link} to="/">
            Home
          </LinkMaterial>
          <LinkMaterial color="inherit" component={Link} to="/productos">
            Productos
          </LinkMaterial>
          <Typography color="textPrimary">Agregar Producto</Typography>
        </Breadcrumbs>

        <Typography variant="h4" gutterBottom>
          Producto
        </Typography>

        <div className={classes.gridcontainer} >
          <Grid container spacing={3} alignItems="flex-start">
            <Grid container item xs={8} spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <ProductDetailsCard 
                  name={name}
                  weight={weight}
                  price={price}
                  currentTag={currentTag}
                  tags={tags}
                  description={description}
                  handleAddTag={handleAddTag}
                  handleNameChange={handleNameChange}
                  handleWeightChange={handleWeightChange}
                  handlePriceChange={handlePriceChange}
                  handleDescriptionChange={handleDescriptionChange}
                />
              </Grid>
            </Grid>
            <Grid container item xs={4} spacing={3}>
              <Grid item xs={12} sm={12} lg={12}>
                <ProductSummaryCard 
                  name={name}
                  price={price}
                  weight={weight}
                  description={description}
                  selectedTags={selectedTags}
                  handleRemoveTag={handleRemoveTag}
                  handleProductSubmit={handleProductSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container >
    </React.Fragment >
  )
}

export default AddProduct;
