import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

const Dashboard = (props) => {
  return (
    <React.Fragment>
      <Container>

        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="textPrimary">Home</Typography>
        </Breadcrumbs>

        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
        
        <Card variant="outlined">
          <CardHeader title="Dashboard" />
          <CardContent></CardContent>
        </Card>
      </Container>
    </React.Fragment>
  )
}

export default Dashboard;
