import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Alerts = (props) => {

  const {
    success,
    successMessage,
    error,
    errorMessage,
    handleSuccessAlertClose,
    handleErrorAlertClose
  } = props;

  return (
    <div>
      {error ?
        <Snackbar open={error} autoHideDuration={5000} onClose={handleErrorAlertClose}>
          <Alert severity="error" onClose={handleErrorAlertClose}>
            {errorMessage}
          </Alert>
        </Snackbar> :
        success ?
          <Snackbar open={success} autoHideDuration={5000} onClose={handleSuccessAlertClose}>
            <Alert severity="info">
              {successMessage}
            </Alert>
          </Snackbar> :
          ''
      }
    </div>
  );
}

export default Alerts;
