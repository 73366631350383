import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  bottomDrawer: {
    background: '#eeeeee',
  },
  bottomButtonContainer: {
    padding: '10px',
    margin: '0px auto'
  },
}));

const SubMenu = (props) => {

  const classes = useStyles();
  const { 
    selectedProducts,
    handleDeleteDialogOpen
  } = props;

  return (
    <Drawer
      anchor='bottom'
      open={selectedProducts.length > 0}
      className={classes.bottomDrawer}
      variant="persistent">
      <ButtonGroup size="large" aria-label="menu de edicion" className={classes.bottomButtonContainer}>
        <Button startIcon={<EditIcon />} disabled={selectedProducts.length !== 1} component={Link} to={`/productos/${selectedProducts[0]}`}>Editar</Button>
        <Button startIcon={<DeleteIcon />} onClick={handleDeleteDialogOpen} disabled={selectedProducts.length !== 1}>Eliminar</Button>
      </ButtonGroup>
    </Drawer>
  );
}

export default SubMenu;
