import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  box: {
    '& > *': {
      margin: theme.spacing(2),
    },
    verticalAlign: 'middle'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    maxWidth: 300,
    '& > *': {
      width: '25ch',
    },
  },
  datePicker: {
    maxWidth: '170px'
  },
  dateCheckbox: {
    margin: '22px 0px'
  },
  addButton: {
    margin: '20px 16px',
    float: 'right'
  }
}));

const OrderFilters = (props) => {
  const classes = useStyles();
  const { 
    onSearchBarFilter,
    orderStatus,
    onStatusFilterChange,
    selectedInitialDate,
    onInitialDateChange,
    selectedEndDate,
    onEndDateChange,
    rangeFlag,
    onRangeFlagChange
  } = props;

  return (
    <Box className={classes.box}>
      <TextField
        id="search-filter"
        className={classes.inputWidth}
        label="Busqueda"
        variant="outlined"
        onChange={onSearchBarFilter}
        placeholder="Cliente"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="tags-filter">Estado</InputLabel>
        <Select
          labelId="tags-filter"
          id="tags-filter"
          value={orderStatus}
          onChange={onStatusFilterChange}
          label="Etiquetas"
        >
          <MenuItem value="todos">Todas las Ordenes</MenuItem>
          <MenuItem value="completa">Completas</MenuItem>
          <MenuItem value="pendiente">Pendientes</MenuItem>
          <MenuItem value="cancelada">Canceladas</MenuItem>
        </Select>
      </FormControl>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          disabled={!rangeFlag}
          variant="inline"
          inputVariant="outlined"
          label="Fecha Inicial"
          format="dd/MM/yyyy"
          value={selectedInitialDate}
          InputAdornmentProps={{ position: "end" }}
          onChange={date => onInitialDateChange(date)}
          className={classes.datePicker}
        />

        <KeyboardDatePicker
          autoOk
          disabled={!rangeFlag}
          variant="inline"
          inputVariant="outlined"
          label="Fecha Final"
          format="dd/MM/yyyy"
          value={selectedEndDate}
          InputAdornmentProps={{ position: "end" }}
          onChange={date => onEndDateChange(date)}
          className={classes.datePicker}
        />

        <Checkbox
          checked={rangeFlag}
          onChange={onRangeFlagChange}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          className={classes.dateCheckbox}
        />
      </MuiPickersUtilsProvider>

      <IconButton aria-label="add" color="primary" className={classes.addButton} component={Link} to="/ventas/orden">
        <AddIcon />
      </IconButton>
    </Box>
  );
}

export default OrderFilters;
