import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import LinkMaterial from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import FilterControl from './filters';
import ProductTable from './table';
import DeleteDialog from './deleteDialog';
import SubMenu from './subMenu';
import { Link } from 'react-router-dom';
import productServices from '../../services/products';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  bottomDrawer: {
    background: '#eeeeee',
  },
  bottomButtonContainer: {
    padding: '10px',
    margin: '0px auto'
  },
}));

const Products = (props) => {

  const classes = useStyles();
  const { 
    id_token, 
    products, 
    tags, 
    handleProductRefresh,
    setSuccess,
    setSuccessMessage,
    setError,
    setErrorMessage
  } = props;
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchBar, setSearchBar] = useState('');
  const [tagsFilter, setTagsFilter] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const resetState = () => {
    setSearchBar('');
    setSelectedProducts([]);
    setTagsFilter([]);
    handleProductRefresh();
  }

  // FILTERS

  const handleSearchBarFilter = (event) => setSearchBar(event.target.value);

  const handleTagsFilterSelect = (event) => {
    const selectedTagArray = Array.from(new Set([...tagsFilter, event.target.value]));

    setTagsFilter(selectedTagArray.sort());
    setSelectedTag('');
  }

  const handleTagsFilterDeselect = (unselectedTag) => {
    const selectedTagArray = tagsFilter.filter(tag => tag !== unselectedTag);

    setTagsFilter(selectedTagArray.sort());
  }

  const handleDeleteDialogClose = () => setDeleteDialog(false);

  const handleDeleteDialogOpen = () => setDeleteDialog(true);

  const handleProductDelete = async () => {
    try {
      const response = await productServices.deleteProduct(id_token, selectedProducts[0]);
      console.log(response);
      if(response === 'ok') {
        setSuccess(true);
        setSuccessMessage('El producto fue eliminado');
        resetState();
        handleDeleteDialogClose();
      }
    } catch (error) {
      setError(true);
      setErrorMessage('Ocurrio un error al eliminar el producto');
    }
  }

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const filteredSet = products.filter(product => {
      if (searchBar !== '' && tagsFilter.length === 0) {
        if (product.nombre.toLowerCase().includes(searchBar.toLowerCase())) return product;
      } else if (searchBar === '' && tagsFilter.length > 0) {
        // if (product.etiquetas.every((tag, index) => tag === tagsFilter[index])) return product;
        if (product.etiquetas.some(tag => tagsFilter.includes(tag))) return product;
      } else if (searchBar !== '' && tagsFilter.length > 0) {
        if (product.nombre.toLowerCase().includes(searchBar.toLowerCase()) && product.etiquetas.some(tag => tagsFilter.includes(tag))) return product;
      } else return product;
    });

    setFilteredProducts(filteredSet.sort());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBar, tagsFilter]);

  return (
    <React.Fragment>
      <Container>
        
        <Breadcrumbs aria-label="breadcrumb">
          <LinkMaterial color="inherit" component={Link} to="/">
            Home
          </LinkMaterial>
          <Typography color="textPrimary">Productos</Typography>
        </Breadcrumbs>

        <Typography variant="h4" gutterBottom>
          Productos
        </Typography>

        <Paper className={classes.root}>

          <FilterControl
            tags={tags}
            searchValue={searchBar}
            selectedTag={selectedTag}
            selectedTagArray={tagsFilter}
            onSearchBarFilter={handleSearchBarFilter}
            onTagsFilterSelect={handleTagsFilterSelect}
            onTagsFilterDeselect={handleTagsFilterDeselect}
            handleProductRefresh={handleProductRefresh}
          />

          <ProductTable
            products={filteredProducts}
            setSelectedProducts={setSelectedProducts}
          />

          <DeleteDialog 
            deleteDialog={deleteDialog}
            products={products}
            selectedProducts={selectedProducts}
            handleDeleteDialogClose={handleDeleteDialogClose}
            handleProductDelete={handleProductDelete}
          />

          <SubMenu 
            selectedProducts={selectedProducts}
            handleDeleteDialogOpen={handleDeleteDialogOpen}
          />

        </Paper>
      </Container>
    </React.Fragment >
  )
}

Products.propTypes = {
  id_token: PropTypes.string,
  products: PropTypes.array,
  tags: PropTypes.array,
};

// Same approach for defaultProps too
Products.defaultProps = {
  id_token: '',
  products: [],
  tags:  [],
};

export default Products;
