import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  login: {
    textAlign: 'center'
  },
  centerCardButton: {
    justifyContent: 'center'
  }
}));

const ForceNewPasswordCard = (props) => {
  const classes = useStyles();
  const { onNewPasswordChange, onNewPasswordSubmit } = props;

  return (
    <Card className={classes.login}>
      <br /><br />
      <CardHeader title="Login" />
      <br />
      <CardContent>
        <form>
          <TextField
            id="newPassword"
            label="New Password"
            variant="outlined"
            type="password"
            onChange={onNewPasswordChange}
            fullWidth={true}
          />
        </form>
      </CardContent>
      <br />
      <CardActions classes={{ root: classes.centerCardButton }}>
        <Button color="primary" onClick={onNewPasswordSubmit}>New Password</Button>
      </CardActions>
      <br /><br />
    </Card>
  )
};

export default ForceNewPasswordCard;
